import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

import respond from '../../styles/mediaQueries';

const StyledWorkSample = styled.div`
	margin: 8rem auto;
	width: 100%;
	max-width: 48rem;

	${respond('bp4')} {
		margin: 0 auto 4rem auto;
	}
`;

const Image = styled.div`
	& img {
		display: block;
		width: 100%;
	}
`;

const Body = styled.div`
	background-color: var(--bgPrimary);
	color: var(--text);
	padding: 4rem 2.4rem;

	${respond('bp2')} {
		padding: 3.2rem .8rem;
	}

	${respond('bp4')} {
		padding: 3.2rem 0rem;
	}

	& p {
		color: var(--text2);
	}
`;

const Links = styled.div`
	display: flex;

	& a {
		color: var(--text);
		margin-right: 2.4rem;
	}
`;

const WorkSample = ({ data, image }) => {
	const { name, description } = data;
	const { alt } = data.images.main;
	const { csLink, demoLink } = data;

	return (
		<StyledWorkSample>
			<Image>
				<Img fluid={image.childImageSharp.fluid} alt={alt} />
			</Image>
			<Body>
				<h4>{name}</h4>
				<p>{description}</p>
				<Links>
					{csLink.link && <Link to={csLink.link}>{csLink.text}</Link>}
					{demoLink.link && (
						<a href={demoLink.link}>{demoLink.text}</a>
					)}
				</Links>
			</Body>
		</StyledWorkSample>
	);
};

export default WorkSample;
