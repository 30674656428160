export default {
	name: 'Ranker',
	description:
		"A full stack app that offers a fun take on decision fatigue in modern life. Users can quickly list and rank items based on aggregated score inputs and save for later recall. Don't make decisions - rank them!",
	date: 'MAR. 2020',
	color: '#5366c6',
	images: {
		illustration: {
			src: require(`../images/landing-illustrations/ranker-illustration.svg`),
			alt: 'Ranker Illustration'
		},
		main: {
			src: '',
			alt: 'Ranker App Website'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/ranker',
		text: 'Case Study'
	},
	demoLink: {
		link: 'http://ranker.netlify.com',
		text: 'View Site'
	},
	sourceLinks: {
		altMessage: '',
		api: {
			link: 'https://github.com/bcorey85/ranker-api',
			text: 'API'
		},
		frontend: {
			link: 'https://github.com/bcorey85/ranker-frontend',
			text: 'Frontend'
		}
	},
	techUsed: [
		'React',
		'MongoDB',
		'Express',
		'Node.js',
		'Jest',
		'JWT',
		'Sass',
		'Netlify',
		'Heroku',
		'MongoDB Atlas',
		'Figma',
		'Affinity Designer'
	]
};
