export default {
	name: 'Rexford Knives',
	description:
		'Collaborative project for a major custom knife manufacturer, Rexford Knives. The website features product galleries, event listings, and mobile responsive tables, to give the company full control over displaying their work.',
	date: 'SEPT. 2019',
	color: '#E67E22',
	images: {
		illustration: {
			src: require(`../images/landing-illustrations/rexford-illustration.svg`),
			alt: 'Rexford Illustration'
		},
		main: {
			src: '',
			alt: 'Rexford Knives Website'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/rexford',
		text: 'Case Study'
	},
	demoLink: {
		link: 'https://www.rexfordknives.com',
		text: 'View Site'
	},
	sourceLinks: {
		altMessage: 'Not Available',
		api: {
			link: '',
			text: 'API'
		},
		frontend: {
			link: '',
			text: 'Frontend'
		}
	},
	techUsed: [ 'React', 'Node.js', 'AWS', 'Figma' ]
};
