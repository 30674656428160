import React from 'react';

const WorkIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeMiterlimit='100'
			clipRule='evenodd'
			viewBox='0 0 135 132'>
			<g fill='none' stroke={fill} strokeWidth='2'>
				<path d='M57.469 12.097c-7.478 5.494-41.677 31.219-43.182 31.733-2.015.688-12.915-14.072-11.126-15.386 3.144-2.308 28.503-22.693 31.98-24.636 3.678-2.055 26.041 5.56 22.328 8.289z' />
				<path d='M27.744 34.197c.648.653 14.491 15.264 26.403 32.282 15.253 21.789 32.72 46.496 33.69 49.916.659 2.326 1.394 13.737 5.68 11.169.534-.32 10.222-6.227 14.729-13.203 1.58-2.446-8.497-7.406-9.93-9.419-4.427-6.218-21.955-28.651-35.894-48.431-11.141-15.809-19.797-30.416-20.94-32.516L27.744 34.197z' />
				<path d='M84.809 28.902c-.038.073-11.582 9.969-26.034 22.349m-9.677 8.288C26.718 78.706 2.086 99.797 2.061 99.878c-.09.297 11.082 15.349 11.167 15.289.444-.314 1.406-2.007 1.406-2.007l4.422 2.078.389-4.989 6.299.904 1.467-5.982 6.801.901 1.129-6.334 6.43.852 1.799-5.905 6.631.869 1.777-5.32 5.847.73.633-5.807 6.515.851.909-3.058m9.495-8.847l6.567 1.211 1.146-6.429 7.15 1.38 3.221-6.9 6.38 1.182' />
				<path d='M84.809 28.902l5.105 7.553c.267.396-4.335 7.574-3.967 8.031 11.825 14.672 12.636 19.378 13.771 20.118.474.308 31.597-21.121 32.994-22.982.941-1.252-1.273-3.792-2.286-3.352-2.357 1.022-6.83 3.864-9.235 5.738-.576.449-17.86-23.118-17.779-23.96.08-.846 5.869-5.163 9.131-8.031.314-.276-1.595-4.024-2.041-3.978-3.258.33-25.693 20.863-25.693 20.863z' />
				<path d='M100.249 26.532c2.993 2.856 13.33 16.461 13.202 19.067-.108 2.219-3.603 4.426-5.381 2.526-1.933-2.067-11.567-14.919-12.789-17.718-1.054-2.411 3.518-5.26 4.968-3.875z' />
			</g>
		</svg>
	);
};

export default WorkIcon;
