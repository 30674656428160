import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import WorkSample from './WorkSample';
import SectionLight from '../shared/SectionLight';

import mmaData from '../../data/mma';
import potwinData from '../../data/potwin';
import rankerData from '../../data/ranker';
import rexfordData from '../../data/rexford';
import vsainteData from '../../data/vsainte';
import timestampData from '../../data/timestamp';

import respond from '../../styles/mediaQueries';

const WorkSamples = styled('div')` 
	margin-bottom: 20rem;

	${respond('bp4')} {
		margin-bottom: 4rem;
	}
`;

const Grid = styled('div')`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 2.4rem;
    margin-top: -8rem;

	${respond('bp3')} {
		grid-gap: 2.4rem;
		margin-top: -12rem;
	}

	${respond('bp4')} {
		display: flex;
		flex-direction: column;
		margin-top: 0rem;  
	}
`;

const GridDivider = styled('hr')`
    border: none;
    height: .3rem;
    background-color: var(--text2);
    grid-column: 1 / 3;

	${respond('bp4')} {
		display: none;
	}
`;

const WorkContent = () => {
	const imageData = useStaticQuery(imageQuery);

	return (
		<WorkSamples>
			<SectionLight>
				<Grid>
					<WorkSample
						data={timestampData}
						image={imageData.timestamp}
					/>
					<WorkSample data={rankerData} image={imageData.ranker} />
					<GridDivider />
					<WorkSample data={mmaData} image={imageData.css} />
					<WorkSample data={vsainteData} image={imageData.vsainte} />
					<GridDivider />
					<WorkSample data={potwinData} image={imageData.potwin} />
					<WorkSample data={rexfordData} image={imageData.rexford} />
				</Grid>
			</SectionLight>
		</WorkSamples>
	);
};

export default WorkContent;

const imageQuery = graphql`
	query WorkImageQuery {
		timestamp: file(
			relativePath: { eq: "case-study/timestamp/timestamp-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		css: file(relativePath: { eq: "case-study/mma/mma-multi.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		potwin: file(
			relativePath: { eq: "case-study/potwin/potwin-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		ranker: file(
			relativePath: { eq: "case-study/ranker/ranker-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		rexford: file(
			relativePath: { eq: "case-study/rexford/rexford-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		vsainte: file(
			relativePath: { eq: "case-study/vsainte/vsainte-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
