export default {
	name: 'Timestamp',
	description:
		'A full stack notes and productivity app focused on helping users maximize efficiency when learning new skills. Users get real-time feedback from progress statistics that show them how much their efforts are paying off.',
	date: 'OCT. 2020',
	color: '#078368',
	images: {
		illustration: {
			src: '',
			alt: ''
		},
		main: {
			src: '',
			alt: 'Timestamp App'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/timestamp',
		text: 'Case Study'
	},
	demoLink: {
		link: 'https://timestampapp.vercel.app/',
		text: 'View Site'
	},
	sourceLinks: {
		altMessage: '',
		api: {
			link: 'https://github.com/bcorey85/timestamp-api',
			text: 'API'
		},
		frontend: {
			link: 'https://github.com/bcorey85/timestamp-frontend',
			text: 'Frontend'
		}
	},
	techUsed: [
		'Typescript',
		'Next.js',
		'Redux',
		'PostgreSQL',
		'Node.js',
		'Sass',
		'Jest',
		'Enzyme',
		'Vercel',
		'Heroku',
		'Figma',
		'Affinity Designer',
		'Affinity Photo'
	]
};
