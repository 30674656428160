export default {
	name: 'Vladimir Sainte Author Website',
	description:
		'Professional website for children’s book author, Vladimir Sainte. The site backend includes a content management system based on Netlify CMS, which lets the owner maintain a blog, post events, and share photos.',
	date: 'SEPT. 2019',
	color: '#81E4DA',
	images: {
		illustration: {
			src: require(`../images/landing-illustrations/vsainte-illustration.svg`),
			alt: 'Vladimir Sainte Illustration'
		},
		main: {
			src: '',
			alt: 'Vladimir Sainte Author Website'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/vsainte',
		text: 'Case Study'
	},
	demoLink: {
		link: 'https://www.vladimirsainte.com',
		text: 'View Site'
	},
	techUsed: [
		'Gatsby',
		'GraphQL',
		'Node.js',
		'Netlify',
		'Netlify CMS',
		'Figma',
		'Affinity Designer',
		'Affinity Photo'
	],
	sourceLinks: {
		altMessage: '',
		api: {
			link: '',
			text: 'API'
		},
		frontend: {
			link: 'https://github.com/bcorey85/vsainte-gatsby',
			text: 'Frontend'
		}
	}
};
