export default {
	name: 'Potwin Construction',
	description:
		'Website for Lincoln, NE based home remodelling company, Potwin Construction. The site features custom built modal and carousel components to feature the company’s work photos. *Project unfortunately cancelled due to Covid-19.',
	date: 'JAN. 2019',
	color: '#221F7A',
	images: {
		illustration: {
			src: require(`../images/landing-illustrations/potwin-illustration.svg`),
			alt: 'Potwin Construction Illustration'
		},
		main: {
			src: '',
			alt: 'Potwin Construction Website'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/potwin',
		text: 'Case Study'
	},
	demoLink: {
		link: 'https://pwc2.netlify.com',
		text: 'Demo Website'
	},
	sourceLinks: {
		altMessage: '',
		api: {
			link: '',
			text: 'API'
		},
		frontend: {
			link: 'https://github.com/bcorey85/potwin-construction',
			text: 'Frontend'
		}
	},
	techUsed: [
		'Gatsby',
		'GraphQL',
		'Node.js',
		'Sass',
		'Netlify',
		'Figma',
		'Affinity Designer',
		'Affinity Photo'
	]
};
