import React from 'react';

import SEO from '../components/Layout/SEO';
import Layout from '../components/Layout/Layout';
import WorkContent from '../components/WorkPage/WorkContent';
import WorkHeader from '../components/WorkPage/WorkHeader';

const Work = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='Work' />
			<WorkHeader />
			<WorkContent />
		</Layout>
	);
};

export default Work;
