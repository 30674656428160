import React from 'react';
import styled from '@emotion/styled';

import WorkIcon from '../svg/icons/WorkIcon';
import SectionDark from '../shared/SectionDark';
import TextBlock from '../shared/TextBlock';

import respond from '../../styles/mediaQueries';

const StyledHeader = styled('div')` 
    margin-bottom: 12rem;

	${respond('bp4')} {
		margin-bottom: 8rem;
	}
`;

const Container = styled('div')` 
	display: flex;
    justify-content: space-between;

	${respond('bp4')} {
		flex-direction: column;
		align-items: center;
	}
`;

const StyledTextBlock = styled(TextBlock)`
	max-width: 48rem;
`;

const Illustration = styled('div')`
    width: 18rem;

	${respond('bp4')} {
		display: none;
	}

	& svg g {
		stroke-width: 2;
	}
`;

const WorkHeader = () => {
	return (
		<StyledHeader>
			<SectionDark>
				<Container>
					<StyledTextBlock>
						<h1>Recent Work</h1>
						<p>
							Here's a few samples of projects that I’ve built
							recently. Feel free to shoot me a message if you
							want to learn more about my development process.
						</p>
					</StyledTextBlock>
					<Illustration>
						<WorkIcon fill={'var(--textInverse2'} />
					</Illustration>
				</Container>
			</SectionDark>
		</StyledHeader>
	);
};

export default WorkHeader;
