export default {
	name: 'MMA Fantasy Sports League',
	description:
		'A concept exploration for a MMA fantasy sports league. Project features an admin dashboard with auto calculating point outcomes and leaderboard changes. Users can sign up for leagues and submit bids for each event.',
	date: 'DEC. 2019',
	color: '#B80000',
	images: {
		illustration: {
			src: require(`../images/landing-illustrations/mma-illustration.svg`),
			alt: 'MMA Fantasy Sports League Illustration'
		},
		main: {
			src: '',
			alt: 'Fantasy MMA League'
		},
		sub1: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		},
		sub2: {
			src: require(`../images/placeholder.svg`),
			alt: 'placeholder'
		}
	},
	csLink: {
		link: '/case-studies/mma',
		text: 'Case Study'
	},
	demoLink: {
		link: 'https://mma-fantasy-demo.netlify.app/',
		text: 'Demo Website'
	},
	sourceLinks: {
		altMessage: '',
		api: {
			link: 'https://github.com/bcorey85/mma-demo-api',
			text: 'API'
		},
		frontend: {
			link: 'https://github.com/bcorey85/mma-demo-frontend',
			text: 'Frontend'
		}
	},
	techUsed: [
		'React',
		'MongoDB',
		'Express',
		'Node.js',
		'Jest',
		'JWT',
		'Sass',
		'Netlify',
		'Heroku',
		'MongoDB Atlas',
		'Figma'
	]
};
